import firebase from 'firebase';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyCUFgwxs26YmPYg9XSG24QonORu5onbvGg',
  authDomain: 'gmo-votaciones.firebaseapp.com',
  projectId: 'gmo-votaciones',
  storageBucket: 'gmo-votaciones.appspot.com',
  messagingSenderId: '626920000715',
  appId: '1:626920000715:web:6223746f173cf516391f4e'
};

const fire = firebase.initializeApp(config);
export default fire;
